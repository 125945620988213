<template>
  <base-section
    id="pro-features"
  >
    <v-container class="text-center">
      <v-row class="justify-center">
        <base-section-heading
          color="grey lighten-2"
          :title="translations.aboutVideoTitle"
        />
      </v-row>
      <media
        :kind="'video'"
        :controls="true"
        :src="[getImgUrl(translations.aboutVideo)]"
        :style="{'width': 'calc(100vw - 24px)', 'max-width': '800px'}"
      />
    </v-container>
  </base-section>
</template>

<script>
  import Media from '@dongido/vue-viaudio'
  import { mapState } from 'vuex'
  import { STORE_NAMESPACE_ABOUT } from '@/views/about/Index.vue'
  import getImgUrl from '@/utils/getImgUrl.js'

  export default {
    name: 'SectionAboutVideo',
    components: {
      Media,
    },
    computed: {
      ...mapState(STORE_NAMESPACE_ABOUT, [
        'translations',
      ]),
    },
    methods: {
      getImgUrl,
    },
    props: {
      imageLeft: {
        type: String,
        default: 'misja.jpg',
      },
      imageRight: {
        type: String,
        default: 'wizja.jpg',
      },
    },
  }
</script>
